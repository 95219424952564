(function(root) {
  var avatarUploadCallback;

  function groupViewShowEditPublicGroupModal() {
    group_id = $("#group-view-container").attr('group-id');
    Burbio.GroupMenu.showEditPublicGroupModal(group_id);
  }

  function groupViewShowEditListModal() {
    group_id = $("#group-view-container").attr('group-id');
    Burbio.GroupMenu.showEditListModal(group_id);
  }

  Burbio.GroupMenu.showNewPublicGroupModal = function() {
    Burbio.GroupMenu.loadPublicGroupModal('/groups/new');
  };

  Burbio.GroupMenu.showEditPublicGroupModal = function(group_id) {
    var url = "/groups/" + group_id + "/edit"
    Burbio.GroupMenu.loadPublicGroupModal(url);
  };

  Burbio.GroupMenu.showEditListModal = function(group_id) {
    var url = "/lists/" + group_id + "/edit"
    Burbio.GroupMenu.loadPublicGroupModal(url);
  };

  Burbio.GroupMenu.loadPublicGroupModal = function(url) {
    $('#modals').load(url, function() {
      $('#group-calendar-modal').modal({ backdrop: 'static' }).modal('show');
      // $('#publicGroupModal').modal({ backdrop: 'static' }).modal('show');
      // $('#publicGroupModal .step-2').hide();
      $('.select-custom').selectpicker();

      $('#group-details .link-toggle').on('click', function(event){
        event.preventDefault();

        $(this).addClass('disabled')
          .closest('#group-details').find('.form-group-hidden').slideDown();
      });

      $('#group_avatar_file').fileupload({
        dataType: 'json',
        method: 'PUT',
        add: function(e, data) {
          avatarUploadCallback = function() {
            return data.submit();
          };
        }
      });

    });
  };

  function groupViewShowEditCarpoolModal() {
    group_id = $("#group-view-container").attr('group-id');
    Burbio.GroupMenu.showEditCarpoolModal(group_id);
  }

  Burbio.GroupMenu.showNewCarpoolModal = function() {
    Burbio.GroupMenu.loadCarpoolModal('/groups/new?carpool=true');
  };

  Burbio.GroupMenu.showEditCarpoolModal = function(group_id) {
    var url = "/groups/" + group_id + "/edit?carpool=true"
    Burbio.GroupMenu.loadCarpoolModal(url);
  };

  Burbio.GroupMenu.loadCarpoolModal = function(url) {
    $('#modals').load(url, function() {
      $('#publicGroupModal').modal({ backdrop: 'static' }).modal('show');
      $('#publicGroupModal .step-2').hide();
    });
  };

  Burbio.GroupMenu.validateCarpoolFirstStep = function(e) {
    e.preventDefault();
    var modal = $("#publicGroupModal");

    $(".step-1 .alert", modal).hide();
    var validCalendar = true;

    if ($(".cal-name", modal).val().length == 0 ) {
      validCalendar = false;
      $(".alert-name", modal).show();
    }
    if ( !validCalendar ) {
      return false;
    }
    $('#publicGroupModal .step-1').hide();
    $('#publicGroupModal .step-2').show();
  };

  Burbio.GroupMenu.uploadAvatarFile = function(group) {
    var uploadURL = '/groups/' + group.id;

    // Now that we've created the calendar we can set the file upload URL
    $('#group_avatar_file').fileupload('option', 'url', uploadURL);

    // Previously saved closure triggers file upload and returns a promise
    var promise = avatarUploadCallback();

    promise.success(function(result, textStatus, jqXHR) {
      window.location = "/groups/" + result.slug;
    }).error(function(jqXHR, errorText, status) {
      console.log("File upload issue");
    });
  };

  Burbio.GroupMenu.uploadListAvatarFile = function(group) {
    var uploadURL = '/lists/' + group.id;

    // Now that we've created the calendar we can set the file upload URL
    $('#group_avatar_file').fileupload('option', 'url', uploadURL);

    // Previously saved closure triggers file upload and returns a promise
    var promise = avatarUploadCallback();

    promise.success(function(result, textStatus, jqXHR) {
      console.log(result);
      window.location = "/lists/" + result.slug;
    }).error(function(jqXHR, errorText, status) {
      console.log("File upload issue");
    });
  };

  Burbio.GroupMenu.groupReturnToStepOne = function(e) {
    e.preventDefault();
    $('#publicGroupModal .step-2').hide();
    $('#publicGroupModal .step-1').show();
  };

  Burbio.GroupMenu.validateGroupFormFirstStep = function(e) {
    e.preventDefault();
    $(".errormsg", modal).hide();
    var validCalendar = true;
    var modal = $("#group-calendar-modal");
    var $formCalendar = $('.form-details');

    if ($("input[name='group[name]']").val().length == 0 ) {
      validCalendar = false;
      $(".errormsg.name", modal).show();
    }

    var ages = [];
    $.each($(".select.select-custom.ages option:selected"), function(){
      ages.push($(this).val());
    });

    if (ages.length == 0 ) {
      validCalendar = false;
      $(".errormsg.ages", modal).show();
    }

    var orgs = [];
    $.each($(".select.select-custom.orgs option:selected"), function(){
      orgs.push($(this).val());
    });

    if (orgs.length == 0 ) {
      validCalendar = false;
      $(".errormsg.orgs", modal).show();
    }

    var affinityCode = $("#group_affinity_codes").val();
    if(affinityCode && !isValidAffinityCode(affinityCode)){
      validCalendar = false;
      $(".errormsg.code", modal).show();
    }

    if(validCalendar){
      var $formNext     = $formCalendar.find('.btn-next');
      var $formBack     = $formCalendar.find('.btn-back');
      var activeClass   = 'active';
      var disabledClass = 'disabled';
      var $this = $(this);
      $this.closest($formCalendar).find('.tab-pane.active').removeClass(activeClass).next('.tab-pane').addClass(activeClass);
      $this.closest($formCalendar).find('.tabs-nav li.active').removeClass(activeClass).next('li').addClass(activeClass);
      $(".btn-next.step-1").removeClass('step-1').addClass('step-2');
      $formBack.removeClass(disabledClass);
    };
  };

  function isValidAffinityCode(code) {
    var response = $.ajax({
      url:  "/groups/validate_affinity_code?code=" + code,
      type: "GET",
      headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
      async: false
    });
    if(response.status == 200){
      return true;
    } else {
      return false;
    }
  };

  Burbio.GroupMenu.validateGroupFormSecondStep = function(e) {
    e.preventDefault();
    $(".errormsg", modal).hide();
    var validCalendar = true;
    var modal = $("#group-calendar-modal");
    var $formCalendar = $('.form-details');

    if($(".select.select-custom.category option:selected").val().length == 0){
      validCalendar = false;
      $(".errormsg.category", modal).show();
    };

    var zip_code = $("#group_location_attributes_zip_code").val()
    if (zip_code.length == 0 || !isValidZip(zip_code)) {
      validCalendar = false;
      $(".errormsg.zip", modal).show();
    };

    if(validCalendar){
      var $formNext     = $formCalendar.find('.btn-next');
      var $formBack     = $formCalendar.find('.btn-back');
      var activeClass   = 'active';
      var disabledClass = 'disabled';
      var $this = $(this);
      $this.closest($formCalendar).find('.tab-pane.active').removeClass(activeClass).next('.tab-pane').addClass(activeClass);
      $this.closest($formCalendar).find('.tabs-nav li.active').removeClass(activeClass).next('li').addClass(activeClass);
      $(".btn-next.step-2").removeClass('step-2').addClass('step-3').hide();
      $('.btn-submit').show();
    };
  };

  Burbio.GroupMenu.validateGroupFormThirdStep = function(e) {
    e.preventDefault();
    $(".errormsg", modal).hide();
    var validCalendar = true;
    var modal = $("#group-calendar-modal");
    var $formCalendar = $('.form-details');

    if($("#field-import-with-feed").prop("checked") && $("#group_calendar_url").val().length == 0){
      validCalendar = false;
      $(".errormsg.calendar", modal).show();
    };

    if(validCalendar){
      modal.find(':input:not(:disabled)').prop('disabled', true);
      $(".btn-submit").hide();
      $formCalendar.find('.btn-back').hide();
      $('.btn-done-loader', modal).show();
    } else {
      return false;
    }
  };

  Burbio.GroupMenu.goBackOneStep = function(e) {
    event.preventDefault();
    var $formCalendar = $('.form-details');
    var $formNext     = $formCalendar.find('.btn-next');
    var $formBack     = $formCalendar.find('.btn-back');
    var activeClass   = 'active';
    var disabledClass = 'disabled';

    var $this = $(this);

    $this.closest($formCalendar).find('.tab-pane.active').removeClass(activeClass).prev('.tab-pane').addClass(activeClass);
    $this.closest($formCalendar).find('.tabs-nav li.active').removeClass(activeClass).prev('li').addClass(activeClass);

    $this.closest($formCalendar).removeClass('form-submit');

    if($formNext.hasClass("step-3")){
      $formNext.show();
      $('.btn-submit').hide();
      $formNext.removeClass("step-3").addClass("step-2");
    } else if($formNext.hasClass("step-2")) {
      $formNext.removeClass("step-2").addClass("step-1");
      $formBack.addClass(disabledClass);
    }
  }

  function isValidZip(zip) {
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip);
  }

  // Depricated
  Burbio.GroupMenu.validatePublicGroupCalendarFirstStep = function(e) {
    e.preventDefault();
    var modal = $("#publicGroupModal");

    $(".step-1 .alert", modal).hide();
    var validCalendar = true;

    if ($(".cal-name", modal).val().length == 0 ) {
      validCalendar = false;
      $(".alert-name", modal).show();
    }

    if ($('#agesSelect .selected-list .item').length == 0 ) {
      validCalendar = false;
      $(".alert-ages", modal).show();
    }

    if ($('#orgsSelect .selected-list .item').length == 0 ) {
      validCalendar = false;
      $(".alert-orgs", modal).show();
    }

    if ( $('#group_website_url').val() != "" && !isValidURL( $('#group_website_url').val() ) ) {
      validCalendar = false;
      $(".alert-url", modal).show();
    }
    if ( !validCalendar ) {
      return false;
    }
    $('#publicGroupModal .step-1').hide();
    $('#publicGroupModal .step-2').show();
  };

  // Depricated
  Burbio.GroupMenu.validatePublicGroupCalendarSecondStep = function() {
    var modal = $("#publicGroupModal");

    $(".step-2 .alert", modal).hide();

    var validCalendar = true;

    if ($(".zip", modal).val().length == 0 ) {
      validCalendar = false;
      $(".alert-zip", modal).show();
    }
    return validCalendar;
  };

  Burbio.GroupMenu.validateListFirstStep = function(e) {
    e.preventDefault();
    var modal = $("#publicGroupModal");

    $(".step-1 .alert", modal).hide();
    var validCalendar = true;

    if ($(".cal-name", modal).val().length == 0 ) {
      validCalendar = false;
      $(".alert-name", modal).show();
    }
    if ( $('#list_website_url').val() != "" && !isValidURL( $('#list_website_url').val() ) ) {
      validCalendar = false;
      $(".alert-url", modal).show();
    }
    if ( !validCalendar ) {
      return false;
    }
    $('#publicGroupModal .step-1').hide();
    $('#publicGroupModal .step-2').show();
  };

  Burbio.GroupMenu.hidePublicGroupModal = function() {
    $("#publicGroupModal").modal("hide");
  };

  Burbio.GroupMenu.removeParent = function(e) {
    e.preventDefault();
    $(this).parent().remove();
  };

  Burbio.GroupMenu.removeAdmin = function(e) {
    e.preventDefault();
    $(this).prev('input.field-admin').remove();
    $(this).remove();
  };

  Burbio.GroupMenu.selectAgeClassification = function(event) {
    event.preventDefault();
    var dropdownEl = $('#agesSelect .dropdown-menu');

    var selectedEl = $(this);
    if (selectedEl.is('.active')) return false;

    var selectedId = selectedEl.parent().data('id');
    var selectedText = selectedEl.text();

    $('li[data-id="'+selectedId+'"]', dropdownEl).addClass("active");

    var itemEl = $(
    '<div class="item" data-id="' + selectedId + '">'+
      '<i class="remove">&times;</i><span>' + selectedText +'</span>'+
      '<input name="group[age_ids][]" class="group_age_ids" type="hidden" value="' + selectedId + '">'+
    '</div>');

    $('#agesSelect .selected-list').append(itemEl);
  };

  Burbio.GroupMenu.removeAgeClassification = function(event) {
    var dropdownEl = $('#agesSelect .dropdown-menu');
    var id =  $(this).parent().data('id');

    $('li[data-id="'+id+'"]', dropdownEl).removeClass("active");

    $(this).parent().remove();
  };

  Burbio.GroupMenu.selectOrgClassification = function(event) {
    event.preventDefault();
    var dropdownEl = $('#orgsSelect .dropdown-menu');

    var selectedEl = $(this);
    if (selectedEl.is('.active')) return false;

    var selectedId = selectedEl.parent().data('id');
    var selectedText = selectedEl.text();

    $('li[data-id="'+selectedId+'"]', dropdownEl).addClass("active");

    var itemEl = $(
    '<div class="item" data-id="' + selectedId + '">'+
      '<i class="remove">&times;</i><span>' + selectedText +'</span>'+
      '<input name="group[organization_ids][]" type="hidden" value="' + selectedId + '">'+
    '</div>');

    $('#orgsSelect .selected-list').append(itemEl);
  };

  Burbio.GroupMenu.removeOrgClassification = function(event) {
    var dropdownEl = $('#orgsSelect .dropdown-menu');
    var id =  $(this).parent().data('id');

    $('li[data-id="'+id+'"]', dropdownEl).removeClass("active");

    $(this).parent().remove();
  };

  Burbio.GroupMenu.appendPublicGroupAdmin = function(event) {
    // $("#account-settings #newPublicGroupCalendarModal #groupAdminsSelect").on('click', '.btn-add', function(event) {
    var $lastItem     = $('.form-duplicate').find('.row-add-admin .field:last');
    var $cloneItem    = $lastItem.clone();
    var email = $lastItem.val();

    event.preventDefault();

    $(".errormsg.admin-email").hide();

    if($.trim(email).length == 0 || !validateEmail(email)) {
      $(".errormsg.admin-email").show();
      return false;
    }

    $cloneItem.val('').insertAfter($lastItem);
    $('<a href="#" class="btn btn-link remove-admin pull-right"><i class="fa fa-times"></i></a>').insertAfter($lastItem);
  };

  Burbio.GroupMenu.validateAndFreezePublicGroupForm = function() {
    if ( ! Burbio.GroupMenu.validatePublicGroupCalendarSecondStep() ) {
      return false;
    }

    var modal = $("#publicGroupModal");
    modal.find(':input:not(:disabled)').prop('disabled', true);
    $(".btn-done").hide();
    $('.btn-done-loader', modal).show();

    return true;
  };

  Burbio.GroupMenu.unFreezePublicGroupForm = function() {

    // var modal = $("#publicGroupModal");
    // modal.find(':input:disabled').prop('disabled', false);
    // $(".btn-done").show();
    // $('.btn-done-loader', modal).hide();

    var modal = $("#group-calendar-modal");
    var $formCalendar = $('.form-details');

    modal.find(':input:disabled').prop('disabled', false);
    $('.feed-option').prop('disabled', false);
    $(".btn-submit").prop('disabled', false).show();
    $formCalendar.find('.btn-back').show();
    $('.btn-done-loader', modal).hide();
    var checked = $('.feed-option:checked').val();
    $('#group_calendar_url').attr("disabled", checked == 'manual');

    return true;
  };

  Burbio.GroupMenu.uploadAvatarAfterCreate = function(event, data, status, xhr) {
    var hasFileToUpload = !!avatarUploadCallback;

    if(hasFileToUpload) {
      Burbio.GroupMenu.uploadAvatarFile(data);
    } else {
      window.location = "/groups/" + data.slug;
    }
  };

  Burbio.GroupMenu.uploadAvatarAfterListFormSubmit = function(event, data, status, xhr) {

    var hasFileToUpload = !!avatarUploadCallback;

    if(hasFileToUpload) {
      Burbio.GroupMenu.uploadListAvatarFile(data);
    } else {
      window.location = "/lists/" + data.slug;
    }
  };

  Burbio.GroupMenu.errorModal = function(event, data, status, xhr) {
    $('.errormsg.calendar2').html(data.responseText).show();
    Burbio.GroupMenu.unFreezePublicGroupForm();
  };

  Burbio.GroupMenu.closeImportMessage = function() {
    $('#group-import-message').remove();
  };

  $(document).ready(function() {
    $(document).on("change", "#group_avatar_file", function() {
      $(".upload-file-name").text($(this).val());
    });

    $("#modals").on("click", ".tabs-nav a", function(e) {
       e.preventDefault();
       return false;
    });

    $(".add-new-group-calendar")
      .on("click", Burbio.GroupMenu.showNewPublicGroupModal);

    $("#group-view .share-box-actions li .edit-group")
      .on("click", groupViewShowEditPublicGroupModal);

    $("#group-view .group-user-actions .group-user li .edit-list")
      .on("click", groupViewShowEditListModal);

    $(".get-started-carpool")
      .on("click", Burbio.GroupMenu.showNewCarpoolModal);

    $("#group-view .group-user-actions .group-user li .edit-carpool")
      .on("click", groupViewShowEditCarpoolModal);

    $("#modals").on("click", "#publicGroupModal .close",                      Burbio.GroupMenu.hidePublicGroupModal);
    $('#modals').on('click', '#publicGroupModal #groupcal-step1',             Burbio.GroupMenu.groupReturnToStepOne);
    $("#modals").on("click", "#publicGroupModal .btn-add-addr",               Burbio.GroupMenu.validatePublicGroupCalendarFirstStep);
    $("#modals").on("click", "#publicGroupModal .btn-add-carpool-addr",       Burbio.GroupMenu.validateCarpoolFirstStep);
    $("#modals").on("click", "#publicGroupModal .btn-add-list-addr",          Burbio.GroupMenu.validateListFirstStep);
    $("#modals").on('click', '#publicGroupModal #agesSelect a',               Burbio.GroupMenu.selectAgeClassification);
    $("#modals").on('click', '#publicGroupModal #agesSelect .remove',         Burbio.GroupMenu.removeAgeClassification);
    $("#modals").on('click', '#publicGroupModal #orgsSelect a',               Burbio.GroupMenu.selectOrgClassification);
    $("#modals").on('click', '#publicGroupModal #orgsSelect .remove',         Burbio.GroupMenu.removeOrgClassification);
    $("#modals").on('click', '.row-add-admin .btn-add',                       Burbio.GroupMenu.appendPublicGroupAdmin);
    $("#modals").on("click", "a.remove-admin",                                Burbio.GroupMenu.removeAdmin);

    $("#modals").on("click", "#publicGroupForm .btn-next.step-1",             Burbio.GroupMenu.validateGroupFormFirstStep);
    $("#modals").on("click", "#publicGroupForm .btn-next.step-2",             Burbio.GroupMenu.validateGroupFormSecondStep);
    $("#modals").on("ajax:beforeSend", "#publicGroupForm",                    Burbio.GroupMenu.validateGroupFormThirdStep);
    $("#modals").on("click", "#publicGroupForm .btn-back",                    Burbio.GroupMenu.goBackOneStep);

    $('#modals').on("click", '.feed-option', function() {
      var checked = $('.feed-option:checked').val();
      if(checked == 'manual'){
        $('#group_calendar_url').val("")
      }
      $('#group_calendar_url').attr("disabled", checked == 'manual');
    });

    // depricated
    // $("#modals").on("ajax:beforeSend", "#publicGroupForm", Burbio.GroupMenu.validateAndFreezePublicGroupForm);

    $('#modals').on('ajax:success', '#publicGroupForm.group-form', Burbio.GroupMenu.uploadAvatarAfterCreate);

    $('#modals').on('ajax:success', '#publicGroupForm.list-edit-modal', Burbio.GroupMenu.uploadAvatarAfterListFormSubmit);

    $('#modals').on('ajax:error', '#publicGroupForm', Burbio.GroupMenu.errorModal);

    $('#remove-import-message').on('ajax:success', Burbio.GroupMenu.closeImportMessage);
  });

})(this);
