(function(root) {

  root.Burbio = {};
  Burbio = root.Burbio;

  Burbio.Controller = {};
  Burbio.DataStore = {};
  Burbio.Calendar = {};
  Burbio.CalendarMenu = {};
  Burbio.GroupMenu = {};

})(this);
