(function(root) {

  var calendars = [];
  var secondary_colors = { "#fba5d8": "#ffdaff",
                           "#ec6183": "#ffa7c9",
                           "#ffac53": "#ffd47b",
                           "#ffec2c": "#fff69a",
                           "#2cd03e": "#9affc0",
                           "#399cce": "#6bceff",
                           "#4767aa": "#83d5ff",
                           "#915e9f": "#c3a4d1"
                         };

  Burbio.DataStore.defaultView = 'week';

  Burbio.DataStore.init = function(calendarData) {
    calendars = calendarData;

    for(i in calendars) {
      calendars[i].color = calendars[i].defaults.color;
      calendars[i].secondarycolor = secondary_colors[calendars[i].defaults.color.toLowerCase()];
    }

    Burbio.CalendarMenu.init();

    if(Burbio.Group) {
      Burbio.Group.Controller.init();
    }

  }

  Burbio.DataStore.refresh = function(calendarData, resetCalendarMenu) {
    calendars = calendarData;

    for(i in calendars) {
      calendars[i].color = calendars[i].defaults.color;
      calendars[i].secondarycolor = secondary_colors[calendars[i].defaults.color.toLowerCase()];
    }

    Burbio.CalendarMenu.refresh(resetCalendarMenu);
  }

  Burbio.DataStore.list = function() {
    return calendars;
  }

  Burbio.DataStore.getCalendar = function(calendar_id) {
    return $.grep(calendars, function(c) { return (c.id === parseInt(calendar_id)) })[0];
  }

  Burbio.DataStore.activeCalendars = function() {
    return $.grep(calendars, function(c) { return c.added_to_view });
  }

  Burbio.DataStore.toggleCalendar = function(id) {
    $.each(calendars, function(index, calendar) {
      if(calendar.id === parseInt(id)) {
        return calendar.added_to_view = !calendar.added_to_view;
      }
    });
  };

})(this);
