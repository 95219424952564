import '@progress/kendo-ui'
/*

Example search data

var dataSearch = [
  {
    eventTitle: "Basketball - Pelham",
    eventGroup: 'Sports'
  },
  {
    eventTitle: "Pelham High School Basketball",
    eventGroup: 'Sports'
  },
  {
    eventTitle: "Pelham Raquet Club Basketball",
    eventGroup: 'Sports'
  }
];

*/

$(document).ready(function() {

  $("#headerSearch").kendoAutoComplete({
    dataSource: new kendo.data.DataSource({
      serverFiltering: true,
      transport: {
        read: {
          url: "/search/autocomplete",
          dataType: "json",
          data: {
            search: {
              query: function() {
                return $("#headerSearch").data("kendoAutoComplete").value();
              }
            }
          }
        }
      },
    }),

    filter: "contains",
    ignoreCase: true,
    suggest: false,
    dataTextField: "name",

    select: function(e) {
      var item = $(e.item).find('div')[0];
      var slug = $(item).data('slug');
      var type = $(item).data('class');

      if (type === 'list') {
        document.location.href = '/lists/' + slug;
      } else {
        document.location.href = '/groups/' + slug;
      }

      trackingHelper.searchProperties = {
        search_terms: $("#headerSearch").val(),
        selected_from_list: true
      }
    },

    template: $("#searchCalendarsAutocompleteTemplate").html()
  });

  $("#headerSearch").on('change input', function(e) {
    if (e.target.value.length > 0) {
      $('#headerSearchClear').show();
    } else {
      $('#headerSearchClear').hide();
    }
  });

  $("#headerSearchClear").on('click', function(e) {
    $('#headerSearch').val('');
    $(this).hide();
  });

  $("#headerSearch").keyup(function(e) {
    if (e.which == 13) {
      $("#headerSearch").data("kendoAutoComplete").close();
      document.location.href = '/my_feed?groupSearch=' + $(this).val();
    }
  });

});
