window.trackingHelper = {
  track: function(event, properties, callback) {
    if (typeof user_details == 'undefined' && $.cookie("visitor_tracking") === "false") {
      return false;
    }

    properties = typeof properties !== 'undefined' ? properties : {};
    properties = $.extend(properties, trackingHelper._contextProperties());
    console.log(event, properties);

    analytics.ready(function() {
      mixpanel.set_config({api_host: "https://api.mixpanel.com"});
      analytics.track(event, properties, {}, function() {
        if (typeof callback === 'function') {
          callback();
        }
      });
    });
  },

  trackingFailed: function(event) {
    trackingHelper.track("Tracking Failed", {event: event})
  },

  addedEvent: function(properties) {
    try {
      trackingHelper.track('Added Event', properties);
    }
    catch(e) {
      trackingHelper.trackingFailed("Added Event");
    }
  },

  trackGroupSearch: function() {
    try {
      properties = {
        user_zip_code: user_details.zipcode,
        first_group_returned_title: $("#rightMenuCalendars tr:first .group-name, #group-search-results tr:first .group-name").text(),
        number_of_groups_returned: $("#rightMenuCalendars tr, #group-search-results tr").length,
        logged_in: typeof user_details !== 'undefined'
      }
      properties = $.extend(properties, trackingHelper.searchProperties);
      trackingHelper.track("Group Search", properties);
      trackingHelper.searchProperties = {}
    }
    catch(e) {}
  },

  eventProperties: function(options) {
    var eventData = Burbio.Calendar.convertEventFromiCalToBurbio(options.data);
    return {
      start_date: eventData.start.toISOString(),
      start_time: moment(eventData.start).format("h:mm"), //eventData.start.getHours() + ":" + ("0" + eventData.start.getMinutes()).slice(-2),
      days_in_the_future: trackingHelper._days_in_the_future(eventData.start)
    }
  },

  copyGroupEventProperties: function(object, loggedIn) {
    loggedIn = typeof loggedIn !== 'undefined' ? loggedIn : true;
    var $this = $(object),
        $eventContainer = $this.parents(".event-item"),
        eventStart = new Date($eventContainer.data("start"));
    return {
      group_name: $("#group-view-container .group-title").text(),
      event_title: $eventContainer.find(".name").text(),
      event_start_date: eventStart.toISOString(),
      event_start_time: moment(eventStart).format("h:mm"),
      event_days_in_the_future: trackingHelper._days_in_the_future(eventStart),
      event_position_in_list: $eventContainer.index(),
      logged_in: loggedIn,
      context: !!$('#group-search-header').length ? ($('#group-search-body').is(':visible') ? "search results" : "my community") : "group or list page"
    }
  },

  searchProperties: {},

  _contextProperties: function() {
    defaultProperties = {timestamp: new Date().toISOString()}
    if (typeof user_details !== 'undefined')
      defaultProperties.email = user_details.email
    return defaultProperties
  },

  _days_in_the_future: function(future) {
    var now = new Date();
    return (future.getTime() - now.getTime()) / (1000 * 60 * 60 * 24)
  },

  trackLandingView: function() {
    try {
      var user_zip_code = $('#group-search').data('user-zip');
      var zip_code = $("#group-search #selectLocation").html();

      var properties = {
        zip_code: zip_code,
        featured_event_id: $("#group-search-body .featured-event").data('id'),
        'used_user_zip_code?': user_zip_code == zip_code
      };

      trackingHelper.track("Viewed my community landing page", properties);
    }
    catch(e) {}
  },

  trackClickedFeaturedEvent: function(callback) {
    try {
      var user_zip_code = $('#group-search').data('user-zip');
      var zip_code = $("#group-search #selectLocation").html();

      var properties = {
        zip_code: zip_code,
        featured_event_id: $("#group-search-body .featured-event").data('id'),
        'used_user_zip_code?': user_zip_code == zip_code,
        'has_image?': $('.featured-event-image').length > 0,
        context: $('#group-search-body').is(':visible') ? "search results" : "my community"
      };

      trackingHelper.track("Clicked featured event", properties, callback);
    }
    catch(e) {}
  },

  trackClickedRecommendedCalendar: function(e, callback) {
    try {
      var user_zip_code = $('#group-search').data('user-zip');
      var zip_code = $("#group-search #selectLocation").html();

      var properties = {
        zip_code: zip_code,
        group_id: $(e.target).data('id'),
        group_name: $(e.target).text(),
        radius: $("#group-search #selectDistance span").html(),
        'used_user_zip_code?': user_zip_code == zip_code
      };

      trackingHelper.track("Clicked on recommended calendar", properties, callback);
    }
    catch(e) {}
  },

  trackCopiedGroupEvent: function() {
    try {
      var properties = {
        context: $('#group-search-body').is(':visible') ? "search results" : "my community"
      };

      trackingHelper.track("Copied group event", properties);
    }
    catch(e) {}
  },

  trackWelcomeTopSignIn: function() {
    try {
      var properties = {
        button: 'Create Events Calendar at top of Welcome Page'
      };

      trackingHelper.track("Click on Create Events Calendar at top", properties);
    }
    catch(e) { trackingHelper.trackingFailed("Click on Create Events Calendar at top");}
  },

  trackWelcomeBottomSignIn: function() {
    try {
      var properties = {
        button: 'Create Events Calendar at bottom of Welcome Page'
      };

      trackingHelper.track("Click on Create Events Calendar at bottom", properties);
    }
    catch(e) { trackingHelper.trackingFailed("Click on Create Events Calendar at bottom");}
  },

  trackWelcomeMainSignUp: function() {
    try {
      var properties = {
        button: 'Sign Up Button on Welcome Page'
      };

      trackingHelper.track("Click sign up button from welcome page", properties);
    }
    catch(e) { trackingHelper.trackingFailed("Click sign up button from welcome page");}
  },

  trackWelcomeSignUpFree: function() {
    try {
      var properties = {
        button: 'Sign Up For Free on Welcome Page',
        fromWhichPage: window.location.href
      };

      trackingHelper.track("Click on sign up for free above list of towns", properties);
    }
    catch(e) { trackingHelper.trackingFailed("Click on sign up for free above list of towns");}
  },

  trackWelcomeSignUpFreeFromVideo: function() {
    try {
      var properties = {
        button: 'Sign Up For Free on Welcome Page Video Modal',
        fromWhichPage: window.location.href
      };

      trackingHelper.track("Click on sign up for free on Welcome Page Video Modal", properties);
    }
    catch(e) { trackingHelper.trackingFailed("Click on sign up for free on Welcome Page Video Modal");}
  },

  trackWelcomeGroupFollow: function(name, category, organizations) {
    try {
      var properties = {
        groupName: name,
        fromWhichPage: window.location.href,
        groupDigestCategory: category,
        groupOrganizations: organizations
      };

      trackingHelper.track("Follow Group from Welcome Page", properties);
    }
    catch(e) { trackingHelper.trackingFailed("Follow Group from Welcome Page");}
  },

  trackGroupFollow: function(name, category, organizations) {
    try {
      var properties = {
        groupName: name,
        fromWhichPage: window.location.href,
        loggedInStatus: ($("#signup-modal").length > 0 ? "logged out" : "logged in"),
        groupDigestCategory: category,
        groupOrganizations: organizations
      };

      trackingHelper.track("Clicked Follow Group from Group Page", properties);
    }
    catch(e) { trackingHelper.trackingFailed("Clicked Follow Group from Group Page");}
  },

  trackSearchGroupFollow: function(name, category, organizations) {
    try {
      var properties = {
        groupName: name,
        fromWhichPage: window.location.href,
        groupDigestCategory: category,
        groupOrganizations: organizations
      };

      trackingHelper.track("Follow Group from Group Search Page", properties);
    }
    catch(e) { trackingHelper.trackingFailed("Clicked Follow Group from Group Search Pag");}
  },


  trackWelcomeZipCodeSearch: function(zip) {
    try {
      var properties = {
        button: 'Search Zip Code',
        zipCodeEntered: zip
      };

      trackingHelper.track("Searched a Zip Code", properties);
    }
    catch(e) { trackingHelper.trackingFailed("Searched a Zip Code");}
  },

  trackWelcomeTownLink: function(town_link) {
    try {
      var properties = {
        townLink: town_link
      };

      trackingHelper.track("Clicked a Town/Publication", properties);
    }
    catch(e) { trackingHelper.trackingFailed("Clicked a Town/Publication");}
  },

  trackWelcomeStateLink: function(state_link) {
    try {
      var properties = {
        townLink: state_link
      };

      trackingHelper.track("Clicked a State", properties);
    }
    catch(e) { trackingHelper.trackingFailed("Clicked a State");}
  },

  trackLandingWelcomeView: function(state_link) {
    try {
      trackingHelper.track("Viewed Home Page (logged out)");
    }
    catch(e) { trackingHelper.trackingFailed("Viewed Home Page (logged out");}
  },

  trackFinishGroupSearchView: function() {
    try {
      trackingHelper.track("CLICKED FINISH and VIEW FEED");
    }
    catch(e) { trackingHelper.trackingFailed("CLICKED FINISH and VIEW FEED");}
  },

  trackGroupBreadcrumbLink: function(link) {
    try {
      var properties = {
        fromWhichPage: window.location.href,
        breadcrumbUrl: link
      };

      trackingHelper.track("Clicked Breadcrumbs", properties);
    }
    catch(e) { trackingHelper.trackingFailed("Clicked Breadcrumbs");}
  },

  trackGroupFacebookShare: function() {
    try {
      var properties = {
        fromWhichPage: window.location.href
      };

      trackingHelper.track("Clicked Facebook Group Share", properties);
    }
    catch(e) { trackingHelper.trackingFailed("Clicked Facebook Group Share");}
  },

};
