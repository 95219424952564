import '@progress/kendo-ui'
import '../bootstrap.min'
import { fromQueryString, toggleSearchMenu } from '../common'

$(function () {
  $('[data-toggle="popover"]').popover()
})
/*

Example search data

var dataSearch = [
  {
    eventTitle: "Basketball - Pelham",
    eventGroup: 'Sports'
  },
  {
    eventTitle: "Pelham High School Basketball",
    eventGroup: 'Sports'
  },
  {
    eventTitle: "Pelham Raquet Club Basketball",
    eventGroup: 'Sports'
  }
];

*/

var categoryData = null;
var interestsData = null;
var madeSelection = false;

function showResults(url, params) {
  $.ajax({
    type: 'get',
    url: url,
    data: params
  }).done(function(data) {
    $("#rightMenuCalendars").html(data);
    $('#rightMenuCalendars').show();
    $('#calendarFilterBtn').show();
    $('#calendarFilterParams').show();
    $('#calendarFilter').hide();
    trackingHelper.trackGroupSearch();
  }).fail(function(error) {
    alert("Could not process request");
  });
}

function onSelect(e) {
  var selectedItem = e.item;
  var url = "/search/selected_group";
  var item = (selectedItem).find('div');
  var params = {id: item.attr('id'), class_name: item.data('class')};

  resetCategoryFilter(false);
  showResults(url, params);

  trackingHelper.searchProperties = {
    search_terms: $(".search-box #searchCalendarsAutocomplete").val(),
    search_zip_code: $("#right-menu #selectLocation").html(),
    search_radius: $("#right-menu #selectDistance span").html(),
    selected_from_list: true
  }
}

function resetCategoryFilter(checked) {
  var all_cboxes = $('#calendarFilterTree').find(':checkbox');
  all_cboxes.each(function() {
    $(this).prop('checked', checked);
  });
}

function filterSearchResults() {
  var query      = $(".search-box #searchCalendarsAutocomplete").val();
  var zipcode    = $("#right-menu #selectLocation").html();
  var range      = $("#right-menu #selectDistance span").html();
  var categories = new Array();
  $('#calendarFilterParams').html('');

  $('#calendarFilterTree :checked').each(function() {
    var val = $(this).val();
    var name = $(this).parent().parent().find('.k-in').html()
    categories.push(val);

    var propItem = $('<div class="item">'+
            '<button type="button" class="close remove-btn" data-category-id="'+val+'"><span aria-hidden="true">&times;</span><span class="sr-only">Remove</span></button>'+
            '<p>'+name+'</p>'+
          '</div>');
    $('#calendarFilterParams').append(propItem);
    $('.remove-btn',propItem).on('click', uncheckFilterCategory);
  });

  zipcode = (zipcode == "Choose zip") ? "" : zipcode;
  query = query.length ? query : "*";

  var url =  "/search/search_groups";
  var params = {search: {'query': query, 'zip': zipcode, 'within': range, 'categories': categories }};

  showResults(url, params);

  trackingHelper.searchProperties = {
    search_terms: query,
    search_zip_code: zipcode,
    search_radius: range,
    selected_from_list: false
  }
}

function uncheckFilterCategory(e) {
  $(this).parent().remove();
  var val = $(this).data('category-id');
  var cbox = $('#calendarFilterTree').find(':checkbox[value='+val+']');
  cbox.prop('checked', false);

  var child_cboxes = cbox.parent().parent().parent().parent().find('.k-group').find(':checkbox');
  child_cboxes.each(function() {
    $(this).prop('checked', false);
  });
  filterSearchResults();
}

$(document).ready(function() {

  $('.finish-group-search').on("click", function(){
    $('#feed-waiting-modal').modal('show');
    setTimeout(function(){
      sessionStorage.setItem('my_feed_social_share', true);
      window.location = "/";
    }, 1000);
  });

  $('#group-search-form').submit(function() {
    $('#waiting-modal').modal('show');
    var valuesToSubmit = $(this).serialize();
    $.ajax({
        type: "GET",
        headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
        url: $(this).attr('action'),
        data: valuesToSubmit
    }).done(function(data){
      $('#waiting-modal').modal('hide');
      $(".groups-search-result").html(data);
      $('.list-checkboxes.list-checkboxes-alt input').prop('checked', false);
    }).fail(function(error) {
      $('#waiting-modal').modal('hide');
      alert("Could not process search, please try again.");
    });
    return false;
  });

  var queries = fromQueryString(document.location.search);

  if (queries.groupSearch) {
    $('#searchCalendarsAutocomplete').val(queries.groupSearch);
    filterSearchResults();
  }

  $("#searchCalendarsAutocomplete").kendoAutoComplete({
    dataSource: new kendo.data.DataSource({
      serverFiltering: true,
      transport: {
        read: {
          url: "/search/autocomplete",
          dataType: "json",
          data: {
            search: {
              query: function() {
                 return $("#searchCalendarsAutocomplete").data("kendoAutoComplete").value();
              },
              categories: function() {
                var categories = new Array();

                $('#calendarFilterTree :checked').each(function() {
                  categories.push($(this).val());
                });

                return categories;
              },
              zip: function() {
                var zipcode    = $("#right-menu #selectLocation").html();
                zipcode = (zipcode == "Choose zip") ? "" : zipcode;
                return zipcode;
              },
              within: function() {
                return $("#right-menu #selectDistance span").html();
              }
            }
           }
        }
      },
    }),

    filter: "contains",
    ignoreCase: true,
    suggest: false,
    dataTextField: "name",

    select: function(e) {
      onSelect(e);
      madeSelection = true;
    },

    template: $("#searchCalendarsAutocompleteTemplate").html()
  });


  $("#searchCalendarsAutocomplete").keyup(function(e){
    if (e.which == 13 && !madeSelection)
      filterSearchResults();
    else
      madeSelection = false;
  });

  $("#searchCalendarsAutocomplete").on('change input',function(e) {
    if (e.target.value.length>0) {
      $('#searchCalendarsAutocompleteClear').show();
    } else {
      $('#searchCalendarsAutocompleteClear').hide();
    }
  });

  $("#searchCalendarsAutocompleteClear").on('click',function(e) {
    $('#searchCalendarsAutocomplete').val('');
    $(this).hide();
  });

  $("#right-menu #selectLocation").popover({
      html : true,
      placement: 'bottom',
      content: function() {
        var content = $($('#zip-popover-template').html()).find('.content');
        var currentZip = $("#right-menu #selectLocation").html();

        content.find('#zipcode').val(currentZip);

        return content;
      },
      title: function() {
        return $($('#zip-popover-template').html()).find('.title');
      }
    });

  $('#right-menu #selectLocation').on('shown.bs.popover', function() {
    var val = $("#right-menu #selectLocation").html();
    $('#right-menu #selectLocationForm .result').html(val);
  });

  $("#right-menu #selectDistance").popover({
    html : true,
    placement: 'bottom',
    content: function() {
      return $('#popoverDistance .content').html();
    },
    title: function() {
      return $('#popoverDistance .title').html();
    }
  });

  $('#right-menu #selectDistance').on('shown.bs.popover', function() {
    var val = $("#selectDistance span").html();
    $('#selectDistanceForm button[data-value='+val+']').addClass('active');
  });

  $(document).on('click', '#right-menu #selectDistanceForm button', function() {
    var val = $(this).data('value');
    $("#right-menu #selectDistance span").html(val);
    $(this).parent().parent().find('.active').removeClass('active');
    $(this).addClass('active');
    $("#right-menu #selectDistance").popover('hide');
    filterSearchResults();
  });

  $('.pop-btn').on('click', function(e) {
    e.preventDefault();
    $('.pop-btn-clicked').removeClass('pop-btn-clicked');
    $(this).addClass('pop-btn-clicked');
  });

  if ($('#right-menu').length) {
    $('html').on('mouseup', function(e) {
      if (!$(e.target).closest('.popover').length && !$(e.target).hasClass('pop-btn-clicked') ) {
        if ($("#selectLocationForm #zipcode").val()) {
          $("#selectLocation").html($("#selectLocationForm #zipcode").val());
        }

        if ($('.popover').length) {
          filterSearchResults();
        }

        $('#selectDistance').popover('hide');
        $('#selectLocation').popover('hide');
      }
    });
  }

  $('.suggestions-from').on('keypress', '#right-menu #zipcode', function(event) {
    if (event.which == 13) {
      if ($("#right-menu #selectLocationForm #zipcode").val().length) {
        $("#right-menu #selectLocation").html($("#right-menu #selectLocationForm #zipcode").val());
      } else {
        $("#right-menu #selectLocation").html("Choose zip");
      }
      $("#right-menu #selectLocation").popover('hide');
      filterSearchResults();
      return false;
    } else if (event.which != 8 && event.which != 0 && (event.which < 48 || event.which > 57)) {
      return false;
    }
  });

  $("#calendarFilterTree").on("click" , ".checkbox", function(event) {
    filterSearchResults();
  });

  $('#calendarFilterBtn').on('click', function() {
    $(this).hide();
    $('#calendarFilterParams').hide();
    $('#rightMenuCalendars').hide();
    $('#calendarFilter').show();
  });

  $('#calendarFilter .filter-btn .close-btn').on('click', function() {
    $('#rightMenuCalendars').show();
    $('#calendarFilterBtn').show();
    $('#calendarFilterParams').show();
    $('#calendarFilter').hide();
  });

  $(".nav-header .search-icon, #get-started-row .get-started-group").on("click", toggleSearchMenu );

  $('#landingSearchModal').on('click', '.btn-search-menu', function(event) {
    toggleSearchMenu();
  });

  $('#right-menu').on('click', '.search-menu-close', function(event) {
    toggleSearchMenu();
  });

});
