(function(root) {

  Burbio.Controller.init = function() {
    if( $(".nav-header .calendar-icon .calendar-menu-btn").hasClass('disabled') ) {
      if(Burbio.Group) {
        Burbio.Group.Controller.init();
      }
    } else {
      initializeDataStore();
    }
  }

  function initializeDataStore() {
    $.ajax({
      url: '/calendars?t=' + (new Date).getTime(),
      type: 'GET',
      dataType: 'json',
      headers: {'cache-control': 'no-cache'},
      success: function(data) {
        getDefaultCalendarView(data);
      }
    });
  }

  function getDefaultCalendarView(calendars) {
    $.ajax({
      url: '/profiles/calendar_view',
      type: 'GET',
      dataType: 'json',
      success: function(data) {
        Burbio.DataStore.defaultView = data.calendar_view;
        Burbio.DataStore.init(calendars);
      }
    });
  }

  Burbio.Controller.refresh = function(resetCalendarMenu) {
    $.ajax({
      url: '/calendars',
      type: 'GET',
      dataType: 'json',
      success: function(data) {
        Burbio.DataStore.refresh(data, resetCalendarMenu);
      }
    });
  }

  $(document).ready(function() {
    Burbio.Controller.init();
  });

})(this);
