// Turbo
import { Turbo } from "@hotwired/turbo-rails";
Turbo.session.drive = false;

// jQuery
import './jquery'
import './jquery-ui.min'
import 'jquery-ujs'

import 'underscore'
import moment from 'moment';
window.moment = moment;
import './CLNDR/clndr.min'

import './jquery.cookie'
import './jQueryFileUpload/jquery.fileupload'
import './jQueryFileUpload/jquery.iframe-transport'
import './jquery.magnific-popup'
import './jquery.fs.scroller.min'
import './jquery.fs.selecter.min'

// Stimulus controllers
import './controllers'

import 'bootstrap'
import 'bootstrap-tour'
import 'chartkick/chart.js'

import './dfp'
import './amoment-2.5.1'

import '@progress/kendo-ui'

import './kendo-ui/split/kendo.core'
import './kendo-ui/split/kendo.progressbar'
import './kendo-ui/split/kendo.data'
import './kendo-ui/split/kendo.popup'
import './kendo-ui/split/kendo.tooltip'
import './kendo-ui/split/kendo.list'
import './kendo-ui/split/kendo.dropdownlist'
import './kendo-ui/split/kendo.calendar'
import './kendo-ui/split/kendo.datepicker'
import './kendo-ui/split/kendo.timepicker'
import './kendo-ui/split/kendo.datetimepicker'
import './kendo-ui/split/kendo.userevents'
import './kendo-ui/split/kendo.numerictextbox'
import './kendo-ui/split/kendo.validator'
import './kendo-ui/split/kendo.binder'
import './kendo-ui/split/kendo.editable'
import './kendo-ui/split/kendo.multiselect'
import './kendo-ui/split/kendo.draganddrop'
import './kendo-ui/split/kendo.window'
import './kendo-ui/split/kendo.scheduler.recurrence'
import './kendo-ui/split/kendo.scheduler.view'
import './kendo-ui/split/kendo.scheduler.dayview'
import './kendo-ui/split/kendo.scheduler.agendaview'
import './kendo-ui/split/kendo.scheduler.monthview'
import './kendo-ui/split/kendo.fx'
import './kendo-ui/split/kendo.mobile.scroller'
import './kendo-ui/split/kendo.view'
import './kendo-ui/split/kendo.mobile.view'
import './kendo-ui/split/kendo.mobile.loader'
import './kendo-ui/split/kendo.mobile.pane'
import './kendo-ui/split/kendo.router'
import './kendo-ui/split/kendo.mobile.application'
import './kendo-ui/split/kendo.mobile.popover'
import './kendo-ui/split/kendo.mobile.shim'
import './kendo-ui/split/kendo.mobile.actionsheet'
import './kendo-ui/split/kendo.scheduler'
import './kendo-ui/split/kendo.autocomplete'
import './kendo-ui/split/kendo.treeview'

import './burbio/base'
import './burbio/group-menu'
import './burbio/calendar-menu'
import './burbio/calendar'
import './burbio/data-store'
import './burbio/controller'
import './burbio/group-search'
import './burbio/group-search-header'

import './tooltip/bootstrap-tooltip'
import './common'

// import './tracking/pingdom_monitoring'
import './tracking/tracking-helper'
