import { showErrorModal } from '../common'
import '../kendo-ui/split/kendo.calendar'
import '../kendo-ui/split/kendo.scheduler'
import '../kendo-ui/split/kendo.scheduler.view'
import '../kendo-ui/split/kendo.scheduler.dayview'
import '../kendo-ui/split/kendo.scheduler.monthview'
import '../kendo-ui/split/kendo.scheduler.view'


(function(root) {
  function currentFilters() {
    var filters = $.map(Burbio.DataStore.activeCalendars(), function(calendar, index) {
      return {field: 'ownerId', operator: "eq", value: calendar.id};
    });

    if(!filters.length) {
      filters = [{field: 'ownerId', operator: "eq", value: -1}];
    }

    return {
      logic: 'or',
      filters: filters
    };
  }

  Burbio.Calendar.applyFilters = function() {
    Burbio.SchedulerDataSource.filter(currentFilters());
  };

  function dataSource() {
    return Burbio.SchedulerDataSource = new kendo.data.SchedulerDataSource({
      transport: {
        read: function(options) {
          $.ajax({
            url: '/calendars/events',
            type: 'GET',
            dataType: "json",
            data: {
              models: kendo.stringify(options.data.models)
            },
            success: function(result) {
              options.success(Burbio.Calendar.convertEventFromBurbioToiCal(result));
              result.length && Burbio.Calendar.applyFilters();
            },
            error: function(result) {
              options.error(options.data);
            }
          });
        },

        create: function(options) {
          trackingProperties = trackingHelper.eventProperties(options);

          // Add original exception date to new exception events
          if(options.data.parent_id) {
            var parentEvent = Burbio.SchedulerDataSource.get(options.data.parent_id);
            options.data.exception_date = parentEvent.recurrenceException.replace(/;$/, '').split(';').pop();
          }

          // If we're dragging an occurrence to create an exception event then we need to use options.data.calendar_id
          var calendarId = options.data.calendar_id || $('.k-popup-edit-form select[data-bind="value:ownerId"]').val();

          $.ajax({
            url: '/calendars/' + calendarId + '/events',
            type: "POST",
            dataType: "json",
            headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
            data: {
              event: Burbio.Calendar.convertEventFromiCalToBurbio(options.data)
            },
            success: function(result) {
              trackingProperties.calendar_name = result.name
              trackingHelper.track("Added Event", trackingProperties);

              options.data.id = result.id;
              options.data.calendar_id = result.calendar_id;
              options.data.ownerId = result.calendar_id;
              options.data.recurrence_rule = result.recurrence_rule;
              options.success(options.data);
            },
            error: function(result) {
              showErrorModal(result.responseJSON.error);
              options.error(options.data);
            }
          });
        },

        update: function(options) {
          $.ajax({
            url: '/calendars/' + options.data.calendar_id + '/events/' + options.data.id,
            type: "PUT",
            dataType: "json",
            data: {
              event: Burbio.Calendar.convertEventFromiCalToBurbio(options.data)
            },
            success: function(result) {
              options.data.calendar_id = result.calendar_id;
              options.data.ownerId = result.calendar_id;
              options.data.recurrence_rule = result.recurrence_rule;
              options.success(options.data);
            },
            error: function(result) {
              showErrorModal(result.responseJSON.error);
              options.error(options.data);
            }
          });
        },

        destroy: function(options) {
          $.ajax({
            url: '/calendars/' + options.data.calendar_id + '/events/' + options.data.id,
            type: "DELETE",
            dataType: "json",
            data: {
              models: kendo.stringify(options.data.models)
            },
            success: function(result) {
              options.success(options.data);
            },
            error: function(result) {
              Burbio.SchedulerDataSource.cancelChanges();
              showErrorModal(result.responseJSON.error);
              options.error(options.data);
            }
          });
        }
      },
      schema: {
        model: {
          fields: {
            id: { from: "id", type: "number" },
            title: { from: "title", defaultValue: "No title" },
            start: { type: "date", from: "start" },
            end: { type: "date", from: "end" },
            description: { from: "description" },
            location: { from: "location" },
            travelTime: { from: "show_travel_time"},
            remind_in: { from: "remind_in"},
            remind_by: { from: "remind_by"},
            ownerId: { from: "calendar_id" },
            calendarName: { from: "calendar_name" },
            recurrenceId: { from: "parent_id" },
            recurrenceRule: { from: "recurrence_rule" },
            recurrenceException: { from: "recurrence_exception" },
            isAllDay: { type: "boolean", from: "all_day", defaultValue: false }
          }
        }
      }
    });
  }


  Burbio.Calendar.loadCalendar = function() {
    $("#scheduler").kendoScheduler({
      date: new Date(),
      mobile: true,
      footer: false,
      height: 800,
      isPersonalCalendar: true,
      groupUserRole: "Calendar",
      views: [
        {
          type: "day",
          selectedDateFormat: "{0:MMMM dd, yyyy}",
          eventTemplate: kendo.template($("#scheduler-day-event").html()),
          dateHeaderTemplate: kendo.template("<span class='k-link k-nav-day'>#=kendo.toString(date, 'dddd M/dd')#</span>"),
          selected: Burbio.DataStore.defaultView == 'day'
        },
        {
          type: "week",
          selectedDateFormat: "{0:MMMM dd} - {1:dd}",
          eventTemplate: kendo.template($("#scheduler-week-event").html()),
          selected: Burbio.DataStore.defaultView == 'week'
        },
        {
          type: "month",
          eventTemplate: kendo.template($("#scheduler-month-event").html()),
          selectedDateFormat: "{0:MMMM yyyy}",
          selected: Burbio.DataStore.defaultView == 'month'
        }
      ],
      timezone: "Etc/UTC",
      messages: {
        editor: {
          editorNewEventTitle: "New Event",
          editorEditEventTitle: "Edit Event",
          title: "Event Title*",
          repeat: "Repeats",
          start: "When*",
          end: "to",
          allDayEvent: "All Day"
        }
      },
      dataSource: dataSource(),
      resources: [
        {
          field: "ownerId",
          title: "Calendar",
          dataValueField: "id",
          dataTextField: "name",
          dataSource: Burbio.DataStore.list()
        }
      ],
      reminders: [
        /* Disabled as per BURBIO-168 */
        /*
        {
            field: "travelTime",
            title: "Show Travel Time",
            optionalField: "Select a time",
            dataSource: [
                { text: "10 minutes", value: "10 minutes" },
                { text: "20 minutes", value: "20 minutes" },
                { text: "30 minutes", value: "30 minutes" },
                { text: "45 minutes", value: "45 minutes" },
                { text: "1 hour", value: "1 hour" },
                { text: "2 hour", value: "2 hour" },
                { text: "3 hour", value: "3 hour" },
                { text: "4 hour", value: "4 hour" }
            ]
        },
        */
        {
          field: "remind_in",
          title: "Remind Me",
          optionalField: "N/A",
          dataSource: [
            { text: "10 minutes", value: "0h:10m" },
            { text: "30 minutes", value: "0h:30m" },
            { text: "1 hour", value: "1h:0m" },
            { text: "2 hour", value: "2h:0m" },
            { text: "3 hour", value: "3h:0m" },
            { text: "4 hour", value: "4h:0m" }
          ]
        },
        {
          field: "remind_by",
          title: "before this event by",
          dataSource: [
            { text: "Email", value: "email" }
            /* Disabled as per BURBIO-168 */
            /*
            ,
            { text: "SMS", value: "sms" }
            */
          ]
        }
      ],
      dataBinding: function(e) {
        $('.k-scheduler-content').scrollTop(494);
      },
      add: function(e) {
        setTimeout(function() {
          var recurrenceSelect = $('.k-scheduler-edit-form input[data-role=dropdownlist]').data("kendoDropDownList");

          recurrenceSelect.bind('change', function() {
            $('.k-recur-end-count').trigger('click');
          });
        }, 100);
      },
      edit: function(e) {
        $('.k-scheduler-edit-form .event-controls').toggle(!e.event.non_notifying);

        if (e.event.isAllDay) {
          $('.k-scheduler-edit-form .duration').text('All Day');
        }
      },

    });

    var tooltipDefault = $("#scheduler").kendoTooltip({
      animation: {
          open: {
            effects: "fade:in",
            duration: 1000
          },
          close: {
            effects: "fade:out",
            duration: 500
          }
      },
      filter: "td[role=gridcell]",
      autoHide: true,
      position: "top",
      width: 150,
      content: 'Double click to add an event'
    }).data("kendoTooltip");

    $("#scheduler").kendoTooltip({
      animation: {
        open: {
          effects: "fade:in",
          duration: 500
        },
        close: {
          effects: "fade:out",
          duration: 1000
        }
      },
      filter: ".k-event",
      autoHide: true,
      position: "top",
      width: 150,
      content: 'Click to edit this event',
      show: function() {
        tooltipDefault.hide();
      }
    });

  }

  Burbio.Calendar.convertEventFromBurbioToiCal = function(burbioEvents) {
    for(var i in burbioEvents) {
      if(burbioEvents[i].reminders.length) {
        burbioEvents[i].remind_in = burbioEvents[i].reminders[0].hours+"h:"+burbioEvents[i].reminders[0].minutes+"m";
        burbioEvents[i].remind_by = burbioEvents[i].reminders[0].action;
      }
    }

    return burbioEvents;
  }

  Burbio.Calendar.convertEventFromiCalToBurbio = function(iCalEvent) {
    var clone = $.extend({}, iCalEvent);

    if(clone.remind_in) {
      clone.reminders_attributes = [];
      clone.reminders_attributes[0] = {};
      clone.reminders_attributes[0].hours = parseInt(clone.remind_in.split(":")[0]);
      clone.reminders_attributes[0].minutes = parseInt(clone.remind_in.split(":")[1]);
      clone.reminders_attributes[0].action = clone.remind_by;
    }

    if(clone.category) {
      clone.category_ids = [];
      clone.category_ids.push(clone.category);
    }

    delete clone.id;
    delete clone.startTimezone;
    delete clone.endTimezone;
    delete clone.calendar_name;
    delete clone.category;
    delete clone.reminders;
    delete clone.remind_in;
    delete clone.remind_by;
    delete clone.invitees;
    delete clone.non_notifying;

    return clone;
  }

})(this);
