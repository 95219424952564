import { hideCalendarMenuNotice } from '../common'

(function(root) {
  $(document).ready(function() {

    Burbio.CalendarMenu.init = function() {
      renderCalendarMenu('doResetCalendarMenu');

      $("#syncPersonalCalendarModal .close").on("click", hideSyncPersonalCalendarDialog);
    }

    Burbio.CalendarMenu.refresh = function(resetCalendarMenu) {
      renderCalendarMenu(resetCalendarMenu);
    }


    // Load Personal Calendar List

    function renderCalendarMenu(resetCalendarMenu) {
      if ( $('#left-menu .personal-calendar .calendar-list').not('.campaign').length ) {
        $('#left-menu .personal-calendar .calendar-list').not('.campaign').load('/calendars?t=' + (new Date).getTime(), function() {
          if(resetCalendarMenu == 'doResetCalendarMenu') {
            resetCalendar();
          }
        });
      }
    }

    function resetCalendar() {
      var scheduler = $('#scheduler').data('kendoScheduler');

      if(scheduler) {
        scheduler.destroy();
        $('.k-scheduler-toolbar').remove();
      }

      Burbio.Calendar.loadCalendar();
    }


    // Personal Calendar Options

    $("#left-menu .personal-calendar .calendar-list").on("click", ".color", toggleCalendarAddToViewFlag);

    function toggleCalendarAddToViewFlag() {
      var $calendarItem = $(this).closest(".calendar-item");
      var calendarId = $calendarItem.attr("cal-id");
      var addedToView = Burbio.DataStore.getCalendar(calendarId).added_to_view;

      $.ajax({
        url: '/calendars/' + calendarId,
        type: 'PUT',
        dataType: 'json',
        data: {
          calendar: {added_to_view: !addedToView}
        },
        success: function(data) {
          $calendarItem.find('.added-to-view').toggleClass("glyphicon glyphicon-ok");
          Burbio.DataStore.toggleCalendar(calendarId);
          Burbio.Calendar.applyFilters();
        }
      });
    }


    // New Personal Calendar

    var icsUploadCallback;

    $("#left-menu .add-personal-calendar").on("click", function() {
      $('#modals').load('/calendars/new', function() {
        icsUploadCallback = null;

        // On selecting a file, assign a closure to
        // icsUploadCallback that submits the ajax upload
        // Uses forceIframeTransport: true for < IE10
        $('#sync-upload-ics').fileupload({
          dataType: 'text',
          add: function(e, data) {
            icsUploadCallback = function() {
              return data.submit();
            };
          }
        });

        $('#newPersonalCalendarModal').modal({ backdrop: 'static' }).modal('show');
      });
    });

    $("#modals").on("click", "#newPersonalCalendarModal .close", hideNewPersonalCalendarDialog);

    $("#modals").on('shown.bs.modal', "#newPersonalCalendarModal", function() {
      $('#newPersonalCalendarModal .cal-name').focus();
    });

    $('#modals').on('ajax:loading', '#new_calendar', function(event, data, status, xhr) {
      $('#newPersonalCalendarModal .sync-upload-ics-loading').show();
    });

    $('#modals').on('ajax:success', '#new_calendar', function(event, data, status, xhr) {
      $('#calendarError').hide();

      var hasFileToUpload = !!icsUploadCallback;

      if(hasFileToUpload) {
        uploadIcsFile(data);
      } else {
        finishPersonalCalendarCreate();
      }
    });

    $('#modals').on('ajax:error', '#new_calendar', function(event, data, status, xhr) {
      $('#calendarError').html(data.responseText).show();
    });

    function uploadIcsFile(calendar) {
      var uploadURL = '/calendars/' + calendar.id + "/events/import";

      // Now that we've created the calendar we can set the file upload URL
      $('#sync-upload-ics').fileupload('option', 'url', uploadURL);

      // Previously saved closure triggers file upload and returns a promise
      var promise = icsUploadCallback();

      promise.success(function(result, textStatus, jqXHR) {
        $('#calendarError').html('Burbio is importing your events!').show();
        setTimeout(finishPersonalCalendarCreate, 1500);
      }).error(function(jqXHR, errorText, status) {
        var error = status == 'OK' ? jqXHR.responseText : 'Server Error: Please Try Again';
        $('#calendarError').html(error).show();
      });
    }

    function finishPersonalCalendarCreate() {
      $('#newPersonalCalendarModal .sync-upload-ics-loading').hide();
      $("#newPersonalCalendarModal :input").attr("disabled", false);

      Burbio.Controller.refresh('doResetCalendarMenu');
      hideNewPersonalCalendarDialog();
    }

    function hideNewPersonalCalendarDialog() {
      $('#newPersonalCalendarModal').modal('hide');
    }


    // Edit Personal Calendar

    $("#left-menu .personal-calendar .calendar-list").on("click", ".edit-cal", showEditPersonalCalendarDialog);
    $("#modals").on("click", "#editPersonalCalendarModal .close", hideEditPersonalCalendarDialog);
    $("#account-settings .calendar-settings .edit-cal").on("click", showEditPersonalCalendarDialog);

    $("#modals").on('shown.bs.modal', "#editPersonalCalendarModal", function() {
      $('#editPersonalCalendarModal .cal-name').focus();
    });

    $('#modals').on('ajax:success', '.edit_calendar', function(event, data, status, xhr) {
      $('#calendarError').hide();
      hideEditPersonalCalendarDialog();
      refreshCalendarSettings(data);
      Burbio.Controller.refresh('doResetCalendarMenu');
    });

    $('#modals').on('ajax:error', '.edit_calendar', function(event, data, status, xhr) {
      $('#calendarError').html(data.responseText).show();
    });

    function showEditPersonalCalendarDialog() {
      hideCalendarMenuNotice();
      var calId = $(this).parent().parent().attr("cal-id");
      $('#modals').load('/calendars/' + calId + '/edit', function() {
        $('#editPersonalCalendarModal').modal({ backdrop: 'static' }).modal('show');
      });
    }

    function hideEditPersonalCalendarDialog() {
      $('#editPersonalCalendarModal').modal('hide');
    }

    function refreshCalendarSettings(data) {
      var $calendarName = $("#account-settings .calendar-settings").find("[data-cal-id='" + data.id + "']");
      $calendarName.html(data.name);
    }


    // Delete Personal Calendar
    $('#modals').on('ajax:success', '.delete-cal', function(event, data, status, xhr) {
      if(data.notice){
        displayCalendarMenuNotice(data.notice)
      }
      $('#calendarError').hide();
      hideEditPersonalCalendarDialog();
      Burbio.Controller.refresh('doResetCalendarMenu');
    });

    $('#modals').on('ajax:error', '.delete-cal', function(event, data, status, xhr) {
      $('#calendarError').html(data.responseText).show();
    });


    // Personal Calendar Sync Options
    $("#left-menu .personal-calendar .calendar-list").on("click", ".sync-cal", showSyncPersonalCalendarDialog);
    $(".dropdown").on("click", "#test-sync-modal", showSyncPersonalCalendarDialog);

    function showSyncConfirmationDialog(group) {
      if(group){
        $('#groupSyncWithGoogleCompleteModal').modal({ backdrop: 'static' });
        $('#groupSyncWithGoogleCompleteModal').modal('show');
      } else {
        $('#syncWithGoogleCompleteModal').modal({ backdrop: 'static' });
        $('#syncWithGoogleCompleteModal').modal('show');
      };
      try
      {
        trackingHelper.track("Sync'd External Calendar", {type: "Google"})
      }
      catch(e){}
    }

    function getDomainName() {
      if (document.domain == 'localhost') {
        return 'localhost:3000';
      } else {
        return document.domain;
      }
    }

    function showSyncPersonalCalendarDialog() {
      var calId = $(this).closest("tr").attr("cal-id") || $(this).attr("cal-id");
      var calLink = $(this).closest("tr").attr("webcal-link") || $(this).attr("webcal-link");
      var syncEmail = $(this).closest("tr").attr("sync-email") || $(this).attr("sync-email");
      var provider = $(this).closest("tr").attr("google-provider") || $(this).attr("google-provider");

      $("#syncPersonalCalendarModal form")[0].reset();

      $("#syncPersonalCalendarModal form").data('cal-id', calId);

      $("#syncPersonalCalendarModal a.ics").attr('href', '/calendars/'+calId+'/get_ics');

      $("#syncPersonalCalendarModal input[type=submit]").one("click", importCalendar);

      $("#syncPersonalCalendarModal a.google-sign-in").one("click", function(e) {
        e.preventDefault(); googleSignIn(calId);
      });

      $("#syncPersonalCalendarModal a.one-way-google-sync").one("click", function(e) {
        e.preventDefault(); oneWayGoogleSync(calId);
      });

      $("#syncPersonalCalendarModal a.two-way-google-sync").one("click", function(e) {
        e.preventDefault(); twoWayGoogleSync();
      });

      $("#syncPersonalCalendarModal a.unsync").one("click", function(e) {
        e.preventDefault(); unSync(calId);
      });

      $("#sync-webcal-link").val(calLink);

      if(syncEmail){
        $(".sync-status-msg").text("Syncing with Google Calendar");
        $("#provider-email").text(syncEmail);
        $(".unsync").text("Unsync");
      }else{
        $(".sync-status-msg").text("Click to sync with Google Calendar");
        $("#provider-email").text(provider);
        $(".unsync").text("");
      }

      $("#syncPersonalCalendarModal .export-webcal .glyphicon-question-sign").tooltip();
      $("#syncPersonalCalendarModal").modal({ backdrop: "static" });

      $("#syncPersonalCalendarModal").modal("show");
    }

    function hideSyncPersonalCalendarDialog() {
      $("#syncPersonalCalendarModal a.one-way-google-sync").off("click");
      $("#syncPersonalCalendarModal a.unsync").off("click");
      $("#syncPersonalCalendarModal").modal("hide");
    }

    function importCalendar(e) {
      e.preventDefault();

      var form  = $(this).closest('form');
      var calId = form.data('cal-id');
      $('#syncPersonalCalendarModal .sync-upload-ics-loading').show();
      $("#syncPersonalCalendarModal :input").attr("disabled", false);

      $.ajax({
        url: '/calendars/' + calId + "/events/import",
        type: "POST",
        dataType: "text",
        headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content') },
        data: new FormData(form[0]),
        processData: false,
        contentType: false,
        success: function(data) {
          $('#syncPersonalCalendarModal .sync-upload-ics-loading').hide();
          $("#syncPersonalCalendarModal :input").attr("disabled", false);
          $('#syncPersonalCalendarModal .sync-error').html('Burbio is importing your events!')
          setTimeout(hideSyncPersonalCalendarDialog, 1500);
          Burbio.Controller.refresh('doResetCalendarMenu');
        },
        error: function(jqXHR, errorText, status) {
          var error = status == 'OK' ? jqXHR.responseText : 'Server Error: Please Try Again';
          $('#syncPersonalCalendarModal .sync-error').html(error);
        }
      });
    };

    function googleSignIn(calId) {
      $.cookie('sync-calendar-id', JSON.stringify({
        calendar_id: calId, signed_in: false
      }) );

      $.cookie("sync-origin", window.location.pathname);
      window.location.href = '/auth/google';
    }

    function oneWayGoogleSync(calId) {
      kendo.ui.progress( $('#syncPersonalCalendarModal'), true );

      $.ajax({
        url:      '/google/export?calendar_id=' + calId,
        type:     'GET',
        dataType: 'json',
        success:  function(data) {
          kendo.ui.progress( $('#syncPersonalCalendarModal'), false );
          hideSyncPersonalCalendarDialog();
          renderCalendarMenu('doResetCalendarMenu');
          showSyncConfirmationDialog(data["group"] == true);
        },
        error:    function(xhr, textStatus, error) {
          console.log(xhr);
          console.log(textStatus);
          console.log(error);
        }
      });
    }

    function twoWayGoogleSync() {
      window.location.href = '/google/calendars';
    }

    function unSync(calId) {
      $.ajax({
        url:      '/google/' + calId,
        type:     'DELETE',
        dataType: 'json',
        success:  function(data) {
          // kendo.ui.progress( $('#syncPersonalCalendarModal'), false );
          hideSyncPersonalCalendarDialog();
          renderCalendarMenu('doResetCalendarMenu');
          location.reload();
          alert("you've been unsynced");
        },
        error:    function(xhr, textStatus, error) {
          console.log(xhr);
          console.log(textStatus);
          console.log(error);
        }
      });
    }


    // Calendar Import Messages

    $('.remove-import-message').on('ajax:success', function(event, data, status, xhr) {
      $('#import-message-' + data.id).remove();
    });

  });

})(this);
